import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';

class Work extends React.Component {
    render() {
        return (
            <Layout>
                <SEO
                    title="Work"
                    theme="moon"
                    keywords={[`career`, `work history`, `resume`, `cv`, `curriculum vitae`]}
                />
                <header>
                    <h1>Work</h1>
                </header>
                <article>
                    <p>
                        I'm currently working on{' '}
                        <a href="https://www.prosaic.works/" target="_blank">
                            Prosaic
                        </a>
                        , a product that lifts your business and home-office expenses magically out
                        of your personal finances — no matter how messy they are.
                    </p>
                    <p>
                        I'm also a partner at{' '}
                        <a href="https://www.cleverlight.partners/" target="_blank">
                            Cleverlight
                        </a>
                        , where I help young New Zealand companies grow, and an investor in{' '}
                        <a href="https://www.spotriskhq.com/" target="_blank">
                            Spotrisk
                        </a>
                        .{' '}
                    </p>
                    <p>
                        In the past I spent 7 years working on Frontend Infrastructure at{' '}
                        <a href="https://slack.com/" target="_blank">
                            Slack
                        </a>
                        . Earlier still, I was the first programmer at{' '}
                        <a href="https://www.lightspeedhq.com/vend/" target="_blank">
                            Vend
                        </a>{' '}
                        (now Lightspeed). I've also worked at DDB, PixelFusion, and several other
                        agencies in New Zealand.
                    </p>
                    <p>
                        Before I was a corporate shill, I studied Computer & Mathematical Science at
                        the University of Waikato. I loved it but I left early, and now I miss
                        learning full-time.
                    </p>
                    <p>
                        Further back still, I spent a winter working at a ski-rental shop in
                        Ohakune. I’d wake up at 7am to fit people with gear, then head up the
                        mountain and ski for 3 or 4 hours, then return to work and take gear returns
                        until late.
                    </p>
                </article>
                <article>
                    <p>
                        <Link to="/">
                            <span className="arrow">←</span>&nbsp;&nbsp;Back to the rest of the
                            website.
                        </Link>
                    </p>
                </article>
            </Layout>
        );
    }
}

export default Work;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
